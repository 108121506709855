<template>
    <div class="page-determine-result">
        <div class="page-header">
            {{probeInfo.deviceId}} <span>待复核</span>
        </div>
        <div class="page-body">
            <div class="xieshang-box box-item">
                <div class="box-title">协商记录</div>
                <div class="box-content">
                    <Steps direction="vertical" :active="0">
                        <Step v-for="(item, index) in probeInfo.logs" :key="index">
                            <p>{{item.content}}</p>
                            <!-- <p class="time">
                                <span>{{item.dayText}}</span>
                                <span>{{item.hourText}}</span>
                            </p> -->
                            <p>{{item.time}}</p>
                        </Step>
                    </Steps>
                </div>
            </div>

            <div class="return-box box-item">
                <div class="box-title">返厂信息</div>
                <div class="box-content">
                    <div class="label-box">
                        <div class="label">故障原因：</div>
                        <div class="label">责任方：</div>
                        <div class="label">认定原因：</div>
                    </div>
                    <div class="label-content">
                        <div class="content">{{probeInfo.tagName}}</div>
                        <div class="content" v-if="probeInfo.preResponsibleParty == 4">英沃</div>
                        <div class="content" v-if="probeInfo.preResponsibleParty == 5">览宋</div>
                        <div class="content">{{probeInfo.liabilityReason || '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <span class="btn left-btn" @click="isShowModal = true">修改判定</span>
            <span class="btn" @click="onsure">维持原判</span>
        </div>

        <Popup v-model="isShowModal" @close="onCancel">
            <div class="modal-box">
                <div class="modal-header">修改判定</div>
                <div class="modal-body">
                    <Field v-model="editTagName" readonly label="故障原因" placeholder="点击选择故障原因" @click="isShowTagAction = true"/>
                    <Field v-model="editResponsiblePartyText" readonly label="责任方" placeholder="点击选择责任方" @click="isShowResponsibleAction = true"/>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-prev" @click="isShowModal = false">取消</div>
                    <div class="btn btn-sure" @click="onchangeStatus">修改并结单</div>
                </div>
            </div>
        </Popup>
        <ActionSheet v-model="isShowTagAction"
            :actions="tagList"
            cancel-text="取消"
            close-on-click-action
            @select="onSelectTag"
            />

        <ActionSheet v-model="isShowResponsibleAction"
            :actions="actiobArr"
            cancel-text="取消"
            close-on-click-action
            @select="onSelectResponsible"
            />
    </div>
</template>

<script>
import { Toast, Step, Steps, Dialog, Popup, Field, ActionSheet } from "vant";
import { getYMD } from '@/utils/time'
export default {
    name: "DetermineRes",
    components: {
        Step,
        Steps,
        Popup,
        Field,
        ActionSheet
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            tagId: 0,
            probeInfo: {},
            tagList: [],
            editTagId: '',
            editTagName: '',
            editResponsibleParty: '',
            isShowModal: false,
            areaList: [],
            showArea: false,
            isShowTagAction: false,
            isShowResponsibleAction: false,
            editResponsiblePartyText: '',
            actiobArr: [
                { name: '英沃', id: 4 },
                { name: '览宋', id: 5 }
            ]
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.name = name
        if (fType != 'lansong') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        vm.loadTag()
    },
    methods: {
        loadProbeInfo() {
            const vm = this
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.$route.query.pId}`
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                if (res.data) {
                    res.data.logs.map(log => {
                        log.dayText = getYMD(log.createAt).split('  ')[0].slice(5, 10)
                        log.hourText = getYMD(log.createAt).split('  ')[1].slice(0, 5)
                        log.time = getYMD(log.createAt)
                    })

                    vm.tagList.map(tag => {
                        tag.id == res.data.preTagId && (res.data.tagName = tag.tagName)
                    })

                    vm.editTagId = res.data.preTagId
                    vm.editTagName = res.data.tagName
                    vm.editResponsibleParty = res.data.preResponsibleParty;
                    vm.editResponsiblePartyText = res.data.preResponsibleParty == 5 ? '览宋' : '英沃'
                }

                vm.probeInfo = res.data
            });
        },

        onCancel() {
            // this.editTagId = ''
            // this.editTagName = ''
            // this.editResponsibleParty = ''
            // this.editResponsiblePartyText = ''
        },

        onSelectTag(item) {
            this.editTagId = item.id;
            this.editTagName = item.name;
        },

        onSelectResponsible(item) {
            this.editResponsibleParty = item.id;
            this.editResponsiblePartyText = item.name;
        },

        loadTag() {
            const vm = this;
            const params = {
                type: 6
            }
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/tags`,
                params
            }).then(res => {
                res.data && res.data.map(tag => {
                    tag.name = tag.tagName
                })
                vm.tagList = res.data
                vm.loadProbeInfo();
            });
        },

        onsure() {
            const vm = this
            Dialog.confirm({
                title: "是否确认维持原判",
                message: `确认后，该订单最终责任方将判定为：<span class="color-span">${vm.probeInfo.preResponsibleParty == 5 ? '览宋' : '英沃'}</span>`,
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                vm.tt = Toast.loading('加载中')
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.probeInfo.id}/confirm`,
                    data: {
                        operator: vm.name,
                        password: vm.$config.base.password,
                        status: 1
                    }
                }).then((res) => {
                    vm.tt.clear()
                    if (res.code != 1) {
                        Toast.fail(res.message || '验收失败')
                        return
                    }
                    Toast.success({
                        message: '验收成功'
                    })
                    vm.$router.back();
                }).catch(err => {
                    console.log(err)
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        onchangeStatus() {
            const vm = this
            let err = ''
            !vm.editTagId && (err = '请选择故障原因')
            !vm.editResponsibleParty && (err = '请选择责任方')
            if (err) {
                Toast.fail(err)
                return
            }
            vm.tt = Toast.loading('加载中')
            vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.probeInfo.id}/confirm`,
                    data: {
                        operator: vm.name,
                        password: vm.$config.base.password,
                        status: 2,
                        tagId: vm.editTagId,
                        responsibleParty: vm.editResponsibleParty
                    }
                }).then((res) => {
                    vm.tt.clear()
                    if (res.code != 1) {
                        Toast.fail(res.message || '验收失败')
                        return
                    }
                    Toast.success({
                        message: '验收成功'
                    })
                    vm.$router.back();
                }).catch(err => {
                    console.log(err)
                });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-determine-result {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    position: relative;
    overflow-y: auto;
    color: @black;
    padding-top: 50px;
    padding: 15px;
    color: @textColor8;
    padding-bottom: 60px;
    .page-header {
        padding-bottom: 15px;
        font-size: 0.3rem;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            margin-left: 10px;
            line-height: 30px;
            padding: 0 5px;
            border-radius: 5px;
            background: @textColor2;
            color: @blank;
            font-size: 0.17rem;
        }
    }

    .page-body {
        .box-item {
            background: @blank;
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            .box-title {
                font-size: 0.16rem;
                position: relative;
                padding-left: 10px;
                padding-bottom: 10px;
                &:after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    height: 12px;
                    border-left: 5px solid @themeColor;
                    left: 0;
                    top: 6px;
                }
            }

            .box-content {
                display: flex;
                justify-content: space-between;
                .label-box {
                    width: 120px;
                    div {
                        margin-bottom: 10px;
                    }
                }
                .label-content {
                    color: @textColor2;
                    div {
                        margin-bottom: 10px;
                    }
                }
                // .van-steps {
                //     padding-left: 100px;
                //     position: relative;
                //     .time {
                //         position: absolute;
                //         left: 0;
                //         top: 0;
                //         width: 40px;
                //     }
                // }
            }
        }
    }

    .page-footer {
        position: fixed;
        left: 15px;
        right: 15px;
        bottom: 15px;
        display: flex;
        justify-content: space-between;
        .btn {
            width: 35%;
            text-align: center;
            border: 1px solid @themeColor;
            color: @themeColor;
            line-height: 40px;
            border-radius: 5px;
            font-size: 0.18rem;
            box-sizing: border-box;
            &.left-btn {
                background: @textColor2;
                border-color: @textColor2;
                color: @blank;
            }
        }
    }

    .van-popup {
        border-radius: 10px;
        .modal-box {
            width: 300px;
            padding: 20px;
            font-size: 0.22rem;
            color: @textColor11;
            font-weight: bold;
            border-radius: 10px;
            text-align: center;
            .modal-body {
                height: 180px;
                padding: 30px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .select-item {
                    line-height: 50px;
                    border: 2px solid rgba(0, 0, 0, 0.1);
                    border-radius: 6px;
                    &.active {
                        border-color: @themeColor;
                        color: @themeColor;
                    }
                }
                .van-field {
                }
            }
            .modal-footer {
                display: flex;
                justify-content: space-between;
                .btn {
                    line-height: 40px;
                    color: @blank;
                    border-radius: 6px;
                    width: 45%;
                    border: 1px solid @textColor9;
                    font-size: 0.16rem;
                    &.btn-prev {
                        color: @textColor9;
                    }

                    &.btn-sure {
                        background: @textColor2;
                        border-color: @textColor2;
                    }
                }
            }
        }
    }
}
.color-span {
    color: @themeColor;
    font-weight: bold;
}
</style>
